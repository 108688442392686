import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import './tailwind.output.css'
import Header from './components/Header'
import Home from './components/Home'
import Footer from './components/Footer'

import HomeScreen from './screens/HomeScreen'
import ProductScreen from './screens/ProductScreen'

const App = () => {
  return (
    <Router>
      <Header />
      <main class="bg-gray-200 pb-16">
        <div className='px-4 py-2 m-auto max-w-screen-xxl'>
          <Route path='/' component={HomeScreen} exact />
          <Route path='/' component={Home} exact />
          <Route path='/product/:id' component={ProductScreen} />
        </div>
      </main>
      <Footer />
    </Router>
  )
}

export default App
