import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

const FooterLabel = styled.span`
  ${tw`p-1 text-xs text-teal-600`};
`
const FooterHeadline = styled.div`
  ${tw`text-base font-medium text-gray-500 uppercase`};
`

const Footer = () => {
  return (
    <footer className='overflow-hidden'>
      <div className='py-5 bg-gray-900'>
        <div className='flex flex-wrap justify-between m-auto text-gray-200 max-w-screen-xxl'>
          <div className='w-48 p-5 '>
            <FooterHeadline>Home</FooterHeadline>
            <a className='block my-3' href='/#'>
              Services
            </a>
            <a className='block my-3' href='/#'>
              Products
            </a>
            <a className='block my-3' href='/#'>
              About Us
            </a>
            <a className='block my-3' href='/#'>
              Pricing
            </a>
            <a className='block my-3' href='/#'>
              Partners <FooterLabel>New</FooterLabel>
            </a>
          </div>
          <div className='w-48 p-5 '>
            <FooterHeadline>User</FooterHeadline>
            <a className='block my-3' href='/#'>
              Sign in
            </a>
            <a className='block my-3' href='/#'>
              New Account
            </a>
            <a className='block my-3' href='/#'>
              Demo <FooterLabel>New</FooterLabel>
            </a>
            <a className='block my-3' href='/#'>
              Career <FooterLabel>We're hiring</FooterLabel>
            </a>
            <a className='block my-3' href='/#'>
              Surveys <FooterLabel>New</FooterLabel>
            </a>
          </div>
          <div className='w-48 p-5 '>
            <FooterHeadline>Resources</FooterHeadline>
            <a className='block my-3' href='/#'>
              Documentation
            </a>
            <a className='block my-3' href='/#'>
              Tutorials
            </a>
            <a className='block my-3' href='/#'>
              Support <FooterLabel>New</FooterLabel>
            </a>
          </div>
          <div className='w-48 p-5 '>
            <FooterHeadline>Product</FooterHeadline>
            <a className='block my-3' href='/#'>
              Our Products
            </a>
            <a className='block my-3' href='/#'>
              Great Deals <FooterLabel>New</FooterLabel>
            </a>
            <a className='block my-3' href='/#'>
              Analytics
            </a>
            <a className='block my-3' href='/#'>
              Mobile
            </a>
          </div>
          <div className='w-48 p-5 '>
            <FooterHeadline>Support</FooterHeadline>
            <a className='block my-3' href='/#'>
              Help Center
            </a>
            <a className='block my-3' href='/#'>
              Privacy Policy
            </a>
            <a className='block my-3' href='/#'>
              Conditions
            </a>
          </div>
          <div className='w-48 p-5 '>
            <FooterHeadline>Contact us</FooterHeadline>
            <a className='block my-3' href='/#'>
              XXX XXXX, Floor 4 San Francisco, CA{' '}
            </a>
            <a className='block my-3' href='/#'>
              mail@company.com{' '}
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
