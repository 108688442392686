import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

const StarFull = styled.div`
  //${tw`inline-block w-3 h-3 bg-green-600 rounded-full`};
  width: 14px;
  height: 14px;
  background: orange;
  display: inline-block;
  border-radius: 100%;
  margin-right: 3px;
  border: 2px solid orange;
`
const StarHalf = styled.div`
  //${tw`inline-block w-3 h-3 bg-yellow-600 rounded-full`};
  width: 14px;
  height: 14px;
  background: linear-gradient(90deg, orange 50%, #fff 50%);
  display: inline-block;
  border-radius: 100%;
  margin-right: 3px;
  border: 1px solid orange;
`
const StarEmpty = styled.div`
  //${tw`inline-block w-3 h-3 bg-red-600 rounded-full`};
  width: 14px;
  height: 14px;
  background: #fff;
  display: inline-block;
  border-radius: 100%;
  margin-right: 3px;
  border: 1px solid orange;
`

const Rating = ({ value, text }) => {
  return (
    <div className='flex items-center text-xs lg:text-sm'>
      <div className='mt-1 mr-1'>
        <span>
          {value >= 1 ? (
            <StarFull />
          ) : value >= 0.5 ? (
            <StarHalf />
          ) : (
            <StarEmpty />
          )}
        </span>
        <span>
          {value >= 2 ? (
            <StarFull />
          ) : value >= 1.5 ? (
            <StarHalf />
          ) : (
            <StarEmpty />
          )}
        </span>
        <span>
          {value >= 3 ? (
            <StarFull />
          ) : value >= 2.5 ? (
            <StarHalf />
          ) : (
            <StarEmpty />
          )}
        </span>
        <span>
          {value >= 4 ? (
            <StarFull />
          ) : value >= 3.5 ? (
            <StarHalf />
          ) : (
            <StarEmpty />
          )}
        </span>
        <span>
          {value >= 5 ? (
            <StarFull />
          ) : value >= 4.5 ? (
            <StarHalf />
          ) : (
            <StarEmpty />
          )}
        </span>
      </div>
      <span>{text && text}</span>
    </div>
  )
}

export default Rating
