const products = [
  {
    _id: '1',
    name: 'Airpods Wireless Bluetooth Headphones',
    image:
      'https://images-na.ssl-images-amazon.com/images/I/41Kz9H3v0yL._AC_SY500_.jpg',
    description:
      'Bluetooth technology lets you connect it with compatible devices wirelessly High-quality AAC audio offers immersive listening experience Built-in microphone allows you to take calls while working',
    brand: 'Apple',
    category: 'Electronics',
    price: 89.99,
    countInStock: 999,
    rating: 4.5,
    numReviews: 12,
  },
  {
    _id: '2',
    name: 'iPhone 11 Pro 256GB Memory',
    image:
      'https://images-na.ssl-images-amazon.com/images/I/61vflt1U5gL._AC_SX522_.jpg',
    description:
      'Introducing the iPhone 11 Pro. A transformative triple-camera system that adds tons of capability without complexity. An unprecedented leap in battery life',
    brand: 'Apple',
    category: 'Electronics',
    price: 599.99,
    countInStock: 999,
    rating: 4.0,
    numReviews: 8,
  },
  {
    _id: '3',
    name: 'Cannon EOS 80D DSLR Camera',
    image:
      'https://images-na.ssl-images-amazon.com/images/I/81NrnJNVWGL._AC_SX522_.jpg',
    description:
      'Characterized by versatile imaging specs, the Canon EOS 80D further clarifies itself using a pair of robust focusing systems and an intuitive design',
    brand: 'Cannon',
    category: 'Electronics',
    price: 929.99,
    countInStock: 999,
    rating: 3,
    numReviews: 12,
  },
  {
    _id: '4',
    name: 'Sony Playstation 4 Pro White Version',
    image:
      'https://images-na.ssl-images-amazon.com/images/I/616UCFmG%2BCL._SL1500_.jpg',
    description:
      'The ultimate home entertainment center starts with PlayStation. Whether you are into gaming, HD movies, television, music',
    brand: 'Sony',
    category: 'Electronics',
    price: 399.99,
    countInStock: 999,
    rating: 5,
    numReviews: 12,
  },
  {
    _id: '5',
    name: 'Logitech G-Series Gaming Mouse',
    image:
      'https://images-na.ssl-images-amazon.com/images/I/41tJa8DO8SL._AC_SL1000_.jpg',
    description:
      'Get a better handle on your games with this Logitech LIGHTSYNC gaming mouse. The six programmable buttons allow customization for a smooth playing experience',
    brand: 'Logitech',
    category: 'Electronics',
    price: 49.99,
    countInStock: 999,
    rating: 3.5,
    numReviews: 10,
  },
  {
    _id: '6',
    name: 'Amazon Echo Dot 3rd Generation',
    image:
      'https://images-na.ssl-images-amazon.com/images/I/61cSwIDSVbL._AC_SX679_.jpg',
    description:
      'Meet Echo Dot - Our most popular smart speaker with a fabric design. It is our most compact smart speaker that fits perfectly into small space',
    brand: 'Amazon',
    category: 'Electronics',
    price: 29.99,
    countInStock: 999,
    rating: 4,
    numReviews: 12,
  },
  {
    _id: '7',
    name: 'Xbox Series X',
    image:
      'https://images-na.ssl-images-amazon.com/images/I/61ux1cU49XL._SX522_.jpg',
    description:
      'Microsoft Xbox One X 1Tb Console With Wireless Controller: Xbox One X Enhanced, Hdr, Native 4K, Ultra Hd (Discontinued)',
    brand: 'Microsoft',
    category: 'Electronics',
    price: 499.0,
    countInStock: 999,
    rating: 4.6,
    numReviews: 3525,
  },
  {
    _id: '8',
    name: 'Bose QuietComfort 35 II Wireless Bluetooth Headphones',
    image:
      'https://images-na.ssl-images-amazon.com/images/I/81%2BjNVOUsJL._AC_SX522_.jpg',
    description:
      'Meet Echo Dot - Our most popular smart speaker with a fabric design. It is our most compact smart speaker that fits perfectly into small space',
    brand: 'Amazon',
    category: 'Electronics',
    price: 299,
    countInStock: 999,
    rating: 4.6,
    numReviews: 31404,
  },
  {
    _id: '9',
    name: 'Nintendo Switch with Neon Blue and Neon Red Joy‑Con',
    image:
      'https://images-na.ssl-images-amazon.com/images/I/61JnrafZ7zL._AC_SX522_.jpg',
    description:
      'Get the gaming system that lets you play the games you want, wherever you are, however you like. Includes the Nintendo Switch console and Nintendo Switch dock in black, with contrasting left and right Joy‑Con controllers-one red, one blue. Also includes all the extras you need to get started. Model number: HAC-001(-01) (product serial number begins with “XKW”) This model includes battery life of approximately 4.5 - 9 hours. The battery life will depend on the games you play. For instance, the battery will last approximately 5.5 hours for The Legend of Zelda: Breath of the Wild (games sold separately).',
    brand: 'Nintendo',
    category: 'Electronics',
    price: 399,
    countInStock: 999,
    rating: 4.8,
    numReviews: 32203,
  },
  {
    _id: '10',
    name: 'Apple iPad Pro (11-inch, Wi-Fi, 64GB) - Space Gray',
    image:
      'https://images-na.ssl-images-amazon.com/images/I/71cI0vnaLiL._AC_SX522_.jpg',
    description:
      'The edge-to-edge Liquid Retina display is not only gorgeous and immersive, but also features incredibly advanced technologies. From True Tone to ProMotion, everything looks stunning and feels responsive on iPad Pro.',
    brand: 'Apple',
    category: 'Electronics',
    price: 649.99,
    countInStock: 999,
    rating: 4.3,
    numReviews: 1041,
  },
  {
    _id: '11',
    name: 'AMD Ryzen 7 3700X 8-Core, 16-Thread Processor',
    image:
      'https://images-na.ssl-images-amazon.com/images/I/717Di3DGIbL._AC_SX679_.jpg',
    description:
      'The AMD Ryzen 7 3700X is the poster child for the AMD Ryzen 3rd Generation processors on the consumer level. Thanks to its new 7nm manufacturing process, it delivers much better performance at a lower power consumption than its predecessor.',
    brand: 'AMD',
    category: 'Electronics',
    price: 269,
    countInStock: 999,
    rating: 4.9,
    numReviews: 9412,
  },
  {
    _id: '12',
    name: 'Dell XPS 13.3" 4K UHD Touch InfinityEdge Display Laptop',
    image:
      'https://images-na.ssl-images-amazon.com/images/I/61N0achVYvL._AC_SX679_.jpg',
    description:
      'Dell’s smallest 13-inch laptop: Your entertainment can go with you everywhere—starting at just 2.7 pounds, the smaller, thinner XPS 13 is ready for take-off. So perfectly sized for airplane tray tables, you won’t even notice when the passenger in front of you reclines their seat.',
    brand: 'Dell',
    category: 'Electronics',
    price: 1399.66,
    countInStock: 999,
    rating: 4.9,
    numReviews: 15,
  },
]

export default products
