import React from 'react'

const Home = () => {
  return (
    <div>
      <main className='my-8'>
        <div className='container mx-auto'>
          <div
            className='h-64 overflow-hidden bg-center bg-cover rounded-md'
            style={{
              backgroundImage: `url(
                'https://images.unsplash.com/photo-1577655197620-704858b270ac?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1280&amp;q=144'
              )`,
            }}
          >
            <div className='flex items-center h-full bg-gray-900 bg-opacity-50'>
              <div className='max-w-xl px-10'>
                <h2 className='text-2xl font-semibold text-white'>
                  Sport Shoes
                </h2>
                <p className='mt-2 text-gray-400'>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Tempore facere provident molestias ipsam sint voluptatum
                  pariatur.
                </p>
                <button className='flex items-center px-3 py-2 mt-4 text-sm font-medium text-white uppercase bg-blue-600 rounded hover:bg-blue-500 focus:outline-none focus:bg-blue-500'>
                  <span>Shop Now</span>
                  <svg
                    className='w-5 h-5 mx-2'
                    fill='none'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path d='M17 8l4 4m0 0l-4 4m4-4H3'></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className='mt-8 md:flex md:-mx-4'>
            <div
              className='w-full h-64 overflow-hidden bg-center bg-cover rounded-md md:mx-4 md:w-1/2'
              style={{
                backgroundImage: `url(
                  'https://images.unsplash.com/photo-1547949003-9792a18a2601?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80'
                )`,
              }}
            >
              <div className='flex items-center h-full bg-gray-900 bg-opacity-50'>
                <div className='max-w-xl px-10'>
                  <h2 className='text-2xl font-semibold text-white'>
                    Back Pack
                  </h2>
                  <p className='mt-2 text-gray-400'>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Tempore facere provident molestias ipsam sint voluptatum
                    pariatur.
                  </p>
                  <button className='flex items-center mt-4 text-sm font-medium text-white uppercase rounded hover:underline focus:outline-none'>
                    <span>Shop Now</span>
                    <svg
                      className='w-5 h-5 mx-2'
                      fill='none'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path d='M17 8l4 4m0 0l-4 4m4-4H3'></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div
              className='w-full h-64 mt-8 overflow-hidden bg-center bg-cover rounded-md md:mx-4 md:mt-0 md:w-1/2'
              style={{
                backgroundImage: `url(
                  'https://images.unsplash.com/photo-1581591546163-a6e9bf7ce12d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80'
                )`,
              }}
            >
              <div className='flex items-center h-full bg-gray-900 bg-opacity-50'>
                <div className='max-w-xl px-10'>
                  <h2 className='text-2xl font-semibold text-white'>Games</h2>
                  <p className='mt-2 text-gray-400'>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Tempore facere provident molestias ipsam sint voluptatum
                    pariatur.
                  </p>
                  <button className='flex items-center mt-4 text-sm font-medium text-white uppercase rounded hover:underline focus:outline-none'>
                    <span>Shop Now</span>
                    <svg
                      className='w-5 h-5 mx-2'
                      fill='none'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path d='M17 8l4 4m0 0l-4 4m4-4H3'></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Home
