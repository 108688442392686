import React from 'react'
import Product from '../components/Product'
import products from '../products'

const HomeScreen = () => {
  return (
    <>
      <h3 className='mt-4 mb-4 text-2xl text-gray-800'>Latest Products:</h3>
      <div className='grid grid-cols-1 gap-4 xs:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 md:col-span-8 lg:col-span-9'>
        {products.map((product, i) => (
          <Product product={product} key={i} />
        ))}
      </div>
    </>
  )
}

export default HomeScreen
