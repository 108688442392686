import React from "react";
import { Link } from "react-router-dom";
import Rating from "../components/Rating";
import Product from "../components/Product";
import products from "../products";

const ProductScreen = ({ match }) => {
	const product = products.find((p) => p._id === match.params.id);
	return (
		<div>
			<div className="container mx-auto">
				<Link
					to="/"
					className="flex items-center mt-6 mb-6 font-medium text-gray-900"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill-rule="evenodd"
						clip-rule="evenodd"
						className="w-6 h-6 mr-2"
					>
						<path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z" />
					</svg>
					Go Back
				</Link>
			</div>
			<section className="p-3 mt-4 overflow-hidden bg-white rounded shadow-sm body-font">
				<div className="container mx-auto">
					<div className="flex flex-wrap mx-auto">
						<div className="flex items-center justify-center lg:w-1/3">
							<img
								alt="ecommerce"
								className="object-contain object-center w-full lg:h-64"
								src={product.image}
							/>
						</div>
						<div className="w-full mt-6 lg:w-1/2 lg:pl-10 lg:py-6 lg:mt-0">
							<h2 className="text-sm tracking-widest text-gray-500 title-font">
								{product.brand}
							</h2>
							<h1 className="mb-1 text-3xl font-medium text-gray-900 title-font">
								{product.name}
							</h1>
							<div className="flex mb-4">
								<Rating
									value={product.rating}
									text={`${product.numReviews} reviews`}
								/>
								<span className="flex py-2 pl-3 ml-3 border-l-2 border-gray-200">
									<a className="text-gray-500">
										<svg
											fill="currentColor"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											className="w-5 h-5"
											viewBox="0 0 24 24"
										>
											<path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
										</svg>
									</a>
									<a className="ml-2 text-gray-500">
										<svg
											fill="currentColor"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											className="w-5 h-5"
											viewBox="0 0 24 24"
										>
											<path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
										</svg>
									</a>
									<a className="ml-2 text-gray-500">
										<svg
											fill="currentColor"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											className="w-5 h-5"
											viewBox="0 0 24 24"
										>
											<path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
										</svg>
									</a>
								</span>
							</div>
							<p className="leading-relaxed">{product.description}</p>
							<div className="flex items-center pb-5 mt-6 mb-5 border-b-2 border-gray-200"></div>
							<div className="flex">
								<span className="text-2xl font-medium text-gray-900 title-font">
									${product.price}
								</span>
								<button className="flex px-6 py-2 ml-auto text-white bg-blue-600 border-0 rounded focus:outline-none hover:bg-blue-700">
									Button
								</button>
								<button className="inline-flex items-center justify-center w-10 h-10 p-0 ml-4 text-gray-500 bg-gray-200 border-0 rounded-full">
									<svg
										fill="currentColor"
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										className="w-5 h-5"
										viewBox="0 0 24 24"
									>
										<path d="M20.84 4.61a5.5 5.5 0 00-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 00-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 000-7.78z"></path>
									</svg>
								</button>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="mt-8">
				<h3 className="mt-4 mb-4 text-2xl text-gray-800">Latest Products:</h3>
				<div className="grid grid-cols-1 gap-4 xs:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 md:col-span-8 lg:col-span-9">
					{products.map((product, i) => (
						<Product product={product} key={i} />
					))}
				</div>
			</section>
		</div>
	);
};

export default ProductScreen;
