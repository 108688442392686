import React from 'react'
import { Link } from 'react-router-dom'
import Rating from './Rating'

const Product = ({ product }) => {
  return (
    <div className='flex flex-col justify-start h-full p-3 overflow-hidden bg-white rounded shadow-sm'>
      <Link
        to={`/product/${product._id}`}
        className='flex h-32 -ml-3 -mr-3 lg:h-48'
      >
        <img
          src={product.image}
          alt=''
          className='object-contain object-center w-full p-2'
        />
      </Link>
      <Link to={`/product/${product._id}`}>
        <div className='mt-2 font-medium leading-5 lg:text-lg lg:mt-4'>
          {product.name}
        </div>
      </Link>
      <div className='mt-2'>
        <Rating value={product.rating} text={`${product.numReviews} reviews`} />
      </div>
      <div className='mt-1 text-xl font-medium'>${product.price}</div>
    </div>
  )
}

export default Product
