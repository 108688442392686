import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

const StyledLink = styled.a`
  ${tw`inline-block px-2 py-1 whitespace-no-wrap hover:underline hover:text-white`};
`
const Logo = styled.div`
  width: 140px;
  @media (min-width: 1024px) {
    width: 160px;
  }
`

const Header = () => {
  return (
    <header className='bg-white'>
      <div className='flex items-center justify-between px-4 py-4 m-auto max-w-screen-xxl lg:px-4'>
        <Link to='/' className=''>
          <Logo>
            <img
              src='https://cdn.worldvectorlogo.com/logos/microsoft.svg'
              alt=''
              className='w-full'
            />
          </Logo>
        </Link>
        <form
          action='/search'
          className='relative hidden w-full ml-8 mr-auto sm:block lg:max-w-6xl'
        >
          <input
            type='search'
            name='query'
            placeholder='Search Products'
            required='required'
            className='w-full px-4 py-2 text-gray-700 bg-white border border-gray-400 rounded-lg focus:outline-none focus:border-gray-400'
          />
          <button
            type='submit'
            className='absolute top-0 bottom-0 right-0 px-4 mt-1 mb-1 mr-1 text-blue-600 focus:outline-none focus:shadow-outline'
          >
            <svg id='search' viewBox='0 0 24 24' className='w-5 h-5'>
              <path
                fill='currentColor'
                d='M24 21.172l-5.66-5.66C19.387 13.932 20 12.038 20 10c0-5.523-4.477-10-10-10S0 4.477 0 10s4.477 10 10 10c2.038 0 3.932-.613 5.512-1.66l5.66 5.66L24 21.172zM4 10c0-3.31 2.69-6 6-6s6 2.69 6 6-2.69 6-6 6-6-2.69-6-6z'
              ></path>
            </svg>
          </button>
        </form>
        <div className='hidden ml-4 text-sm text-gray-700 whitespace-no-wrap lg:text-base lg:flex'>
          <Link to='/login' className='flex items-center p-2'>
            <div className='flex flex-row leading-4'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                className='w-8 h-8 mr-2 text-blue-700'
              >
                <path
                  fill='currentColor'
                  d='M20.822 18.096c-3.439-.794-6.641-1.49-5.09-4.418 4.719-8.912 1.251-13.678-3.732-13.678-5.082 0-8.465 4.949-3.732 13.678 1.598 2.945-1.725 3.641-5.09 4.418-2.979.688-3.178 2.143-3.178 4.663l.005 1.241h1.995c0-3.134-.125-3.55 1.838-4.003 2.851-.657 5.543-1.278 6.525-3.456.359-.795.592-2.103-.338-3.815-2.058-3.799-2.578-7.089-1.423-9.026 1.354-2.275 5.426-2.264 6.767-.034 1.15 1.911.639 5.219-1.403 9.076-.91 1.719-.671 3.023-.31 3.814.99 2.167 3.707 2.794 6.584 3.458 1.879.436 1.76.882 1.76 3.986h1.995l.005-1.241c0-2.52-.199-3.975-3.178-4.663z'
                />
              </svg>
              <div className='flex flex-col'>
                <div className='text-lg font-bold text-blue-700'>Account</div>
                <div className='hidden text-sm lg:block'>
                  Sign in &amp; register
                </div>
              </div>
            </div>
          </Link>
          <Link to='/cart' className='flex items-center p-2 ml-4'>
            <div className='flex flex-row leading-4'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                className='w-8 h-8 mr-2 text-blue-700'
              >
                <path
                  fill='currentColor'
                  d='M24 3l-.743 2h-1.929l-3.474 12h-13.239l-4.615-11h16.812l-.564 2h-13.24l2.937 7h10.428l3.432-12h4.195zm-15.5 15c-.828 0-1.5.672-1.5 1.5 0 .829.672 1.5 1.5 1.5s1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm6.9-7-1.9 7c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5z'
                />
              </svg>
              <div className='flex flex-col'>
                <div className='text-lg font-bold text-blue-700'>Cart</div>
                <div className='hidden text-sm lg:block'>0 Items</div>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className='flex items-center justify-between px-4 py-2 m-auto text-gray-200 bg-blue-600 lg:px-4'>
        <div className='w-full m-auto overflow-x-auto max-w-screen-xxl'>
          <nav className='flex flex-no-wrap'>
            <StyledLink href='/'>Today's Deals</StyledLink>
            <StyledLink href='/'>Customer Service</StyledLink>
            <StyledLink href='/'>Gift Cards</StyledLink>
            <StyledLink href='/'>Registry</StyledLink>
            <StyledLink href='/'>Sell</StyledLink>
          </nav>
        </div>
      </div>
    </header>
  )
}

export default Header
